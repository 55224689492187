.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 150px);
}
.layout .customer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.layout .customer .input-row {
  display: flex;
  gap: 5px;
}
.layout .customer .input-container {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
}
.layout .customer .input-container .field-name {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 9px;
}
.layout .customer .input-container input {
  font-family: "Poppins-Medium";
  outline: none;
  font-style: normal;
  min-width: 335px;
  font-weight: 400;
  border: solid 1px #797D88;
  border-radius: 8px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 18px;
  padding: 16px 0px 14px 27px;
}
.layout .customer .input-container .input {
  font-family: "Poppins-Medium";
  outline: none;
  font-style: normal;
  min-width: 335px;
  font-weight: 400;
  border: solid 1px #797D88;
  border-radius: 8px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 18px;
  padding: 16px 0px 14px 27px;
}/*# sourceMappingURL=Cutomer.css.map */