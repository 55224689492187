.btn{
    padding: 14px 80px;
    font-family: 'Poppins-Medium';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 20px;
width: 335px;
border-radius: 8px;
border: none;
background-color: black;
outline: none;
text-align: center;
max-width: 335px;
color: #FFFFFF;
margin-bottom: 24px;

}
.g-btn{
    padding: 14px 80px;
    font-family: 'Poppins-Medium';
font-style: normal;
font-weight: 400;
width: 335px;
font-size: 16px;
line-height: 20px;
border-radius: 8px;
border: none;
background-color: rgb(0, 0, 0,0.3);
outline: none;
text-align: center;
color: #FFFFFF;
margin-bottom: 24px;

}
.sbtn{
    padding: 14px 35px;
    font-family: 'Poppins-Medium';
font-style: normal;
width: 127px;
font-weight: 400;
font-size: 16px;
line-height: 20px;
background-color: #FFFFFF;
border-radius: 8px;
border: 1px solid #D3BC8C;
outline: none;
text-align: center;
color: #000000;
}
.suvbtn{
padding: 14px 35px;
font-family: 'Poppins-Medium';
font-style: normal;
width: 127px;
font-weight: 400;
font-size: 16px;
line-height: 20px;
border-radius: 8px;
border: none;
background: #D3BC8C;
outline: none;
text-align: center;
color: #FFFFFF;
}