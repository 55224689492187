.layout{
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 150px);
  .details{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    .input-row{
        display: flex;
        gap: 5px;
        .close{
          font-size: 21px;
          display: flex;
          align-items: flex-end;
          margin: 0px 0px 16px 5px;
      }
    }
    .count-container{
        display: flex;
        flex-direction: column;
        height: 335px;
        padding: 10px 0px ;
        overflow-y: scroll;
    }
    .count-container::-webkit-scrollbar {
        display: none;
      }
      
      /* Hide scrollbar for IE, Edge and Firefox */
      .count-container {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
      }
}}
