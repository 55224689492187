.tab-row {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 320px;
  gap: 10px;
  margin: 0 auto;
}
.tab-row .line {
  width: 75%;
  margin: 0 auto;
  background-color: black;
  position: absolute;
  align-self: center;
  height: 1px;
  left: 20px;
}
.tab-row .tab-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.tab-row .tab-btn .btn-img {
  max-width: 48px;
  width: 100%;
  height: 100%;
  max-height: 48px;
}
.tab-row .tab-btn p {
  font-style: normal;
  font-family: "Poppins-Medium";
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}/*# sourceMappingURL=Tab.css.map */