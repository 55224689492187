.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 28px;
  width: 330px !important;
  max-width: 386px;
  z-index: 111111111111111100000000000000;
  color: black;
  background-color: #fff;
  border-radius: 1em;
  transform: translate(-50%, -50%);
  outline: transparent;
}
.modal .btn-row {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
}
.modal .item-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  align-items: center;
  /* Hide scrollbar for IE, Edge and Firefox */
}
.modal .item-box .input-container {
  width: 300px;
}
.modal .item-box .input-container .input {
  min-width: 300px !important;
}
.modal .item-box .item-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 200px;
  border: 1px solid black;
  border-radius: 8px;
  overflow-y: scroll;
  gap: 8px;
  width: 300px;
}
.modal .item-box .item-container .item {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  width: 100%;
  line-height: 18px;
  padding: 5px 0px 5px 13px;
  text-align: initial;
  color: #000000;
}
.modal .item-box .item-container .item-hover {
  font-style: normal;
  font-weight: 400;
  width: 100%;
  text-align: initial;
  font-size: 12px;
  line-height: 18px;
  padding: 5px 0px 5px 13px;
  color: #000000;
  background: rgba(0, 0, 0, 0.08);
}
.modal .item-box .item-container::-webkit-scrollbar {
  display: none;
}
.modal .item-box .item-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.modal .modal-row {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.modal .modal-row .close {
  color: #D3BC8C;
  padding: 10px 10px 0px 0px;
  font-size: 24px;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 111111111111111120;
}/*# sourceMappingURL=Modal.css.map */