.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 111111111111111120;
}
.overlay .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  padding: 0px !important;
  font-size: 28px;
  width: 385px;
  max-width: 386px;
  z-index: 111111111111111100000000000000;
  color: black;
  background-color: #fff;
  border-radius: 16px;
  transform: translate(-50%, -50%);
  outline: transparent;
}
.overlay .modal .span {
  font-size: 12px;
  padding: 60px 0px 30px 0px;
}

.pdf-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pdf-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.pdf-container {
  border-radius: 0px;
  max-height: 440px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.pdf-container .warn p {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
}
.pdf-container .head {
  display: flex;
  width: 100%;
  background-color: #D3BC8C;
  color: white;
  border-radius: 16px 16px 0px 0px;
}
.pdf-container .head h4 {
  padding: 14px 0px 14px 28px;
  margin: 0;
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.pdf-container .pdf-box {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0px 10px;
  max-width: 360px;
  /* Hide scrollbar for IE, Edge and Firefox */
}
.pdf-container .pdf-box .top {
  max-width: 360px;
  width: 100%;
}
.pdf-container .pdf-box .top h5 {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  text-align: initial;
  line-height: 21px;
  color: #000000;
}
.pdf-container .pdf-box .signatures {
  width: 98px;
  height: 53px;
  -o-object-fit: contain;
     object-fit: contain;
  margin: 0 auto;
}
.pdf-container .pdf-box .bottom::-webkit-scrollbar {
  display: none;
}
.pdf-container .pdf-box .bottom {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.pdf-container .pdf-box .bottom {
  display: flex;
  max-height: 120px;
  overflow-y: scroll;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  align-items: flex-start;
}
.pdf-container .pdf-box .bottom .item-row {
  display: flex;
  align-items: center;
  gap: 16px;
}
.pdf-container .pdf-box .bottom .item-row .p {
  font-family: "Poppins-Medium";
  font-style: normal;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  line-height: 20px;
  width: 24px;
  height: 21px;
  border: 1px solid #D3BC8C;
  color: #D3BC8C;
}
.pdf-container .pdf-box .bottom .item-row .ps {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  margin: 0;
  line-height: 20px;
  color: #000000;
}
.pdf-container .pdf-row {
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 0px 10px;
  max-width: 360px;
}
.pdf-container .pdf-row .right {
  width: 50%;
  display: flex;
  align-items: center;
}
.pdf-container .pdf-row .right p {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  margin: 0 !important;
  font-size: 12px;
  text-align: initial;
  line-height: 18px;
  color: #000000;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.pdf-container .pdf-row .left {
  width: 50%;
}
.pdf-container .pdf-row .left h5 {
  font-family: "Poppins-Medium";
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: initial;
  line-height: 21px;
  color: #000000;
}/*# sourceMappingURL=ModalData.css.map */