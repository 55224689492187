.layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  height: calc(100vh - 110px);
}
.layout .installer-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.layout .installer-details .input-row {
  display: flex;
  gap: 5px;
}
.layout .installer-details .input-message {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: flex-start;
}
.layout .installer-details .input-message .field-message {
  font-family: "Poppins-Medium";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: black;
  margin-bottom: 9px;
}
.layout .installer-details .input-message .message {
  word-wrap: break-word;
  word-break: break-all;
  outline: none;
  font-family: "Poppins-Medium";
  font-style: normal;
  min-width: 335px;
  font-weight: 400;
  height: 110px;
  max-height: 200px;
  border: solid 1px #797D88;
  border-radius: 8px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  line-height: 18px;
  padding: 16px 0px 14px 27px;
}/*# sourceMappingURL=InstallerDetails.css.map */